<template>
<v-container class="transfer-confirm-wrapper">
    <div class="transfer-confirm">
        <div class="confirm-header">
            <div class="left"></div>
            <div class="right">
                <p>合计付款<span>{{total}}元</span></p>
                <p>账单金额 <span>{{toFix(info.price)}}元</span></p>
                <p>手续费 <span>{{toFix(commission)}}元</span></p>
            </div>
        </div>
        <v-divider class="line" />
        <div class="transfer-info">
            <h4 class="transfer-item-title">付款信息</h4>
            <ul>
                <li>
                    付款人 <span>{{info.payName}}</span>
                </li>
                <li>
                    付款人手机号<span>{{info.mobile}}</span>
                </li>
                <li class="pay-type">
                    付款方式
<!--          v-if="isUnpay"        -->
<!--                    <div >-->
<!--                        <v-radio-group v-model="info.payType" class="pay-method">-->
<!--                            <v-radio value="1" class="pay-method-item"-->
<!--                                v-if="!onlyPayMethod || onlyPayMethod === '1'"-->
<!--                                :disabled="!!onlyPayMethod"-->
<!--                            >-->
<!--                                <template v-slot:label>-->
<!--                                    <i class="pay-icon ali-pay-icon"></i>支付宝-->
<!--                                </template>-->
<!--                            </v-radio>-->
<!--                            <v-radio value="2" v-if="!onlyPayMethod || onlyPayMethod === '2'" :disabled="!!onlyPayMethod">-->
<!--                                <template v-slot:label>-->
<!--                                    <i class="pay-icon wx-pay-icon"></i>微信-->
<!--                                </template>-->
<!--                            </v-radio>-->
<!--                        </v-radio-group>-->
<!--                    </div>-->
                    <span >
                        <span v-if="info.payType === '1'"><i class="pay-icon ali-pay-icon"></i>支付宝支付</span>
                        <span v-if="info.payType === '4'"><i class="pay-icon ali-pay-icon"></i>支付宝支付(银联商务)</span>
                        <span v-if="info.payType === '2'"><i class="pay-icon wx-pay-icon"></i>微信支付</span>
                        <span v-if="info.payType === '3'"><i class="pay-icon wx-pay-icon"></i>微信支付(银联商务)</span>
                    </span>
                </li>
                <li class="remark-line">
                    缴费人附言<span>{{info.remark}}</span>
                </li>
            </ul>
            <p class="price-tip">
                {{rateInfo}}
            </p>
            <h4 class="transfer-item-title">学生信息</h4>
            <ul>
                <li>姓名 <span>{{info.name}}</span></li>
                <li>班级 <span>{{info.class}}</span></li>
                <li>用户编号 <span>{{info.idCard}}</span></li>
                <li>项目 <span>{{info.subjectName}}</span></li>
            </ul>
        </div>
        <div class="price-info">
            <span>￥{{total}}</span>
            <v-btn
                class="button-submit"
                rounded
                depressed
                color="primary"
                :disabled="!canPay"
                @click="submit"
            >
                确认付款
            </v-btn>
        </div>
    </div>
</v-container>
</template>

<script>
import {aliPay, wxPay, getRate, transferSave, ylPay} from '@/service/service';
export default {
    name: 'TransferConfirm',

    data() {
        return {
            info: {},
            canPay: false,
            isUnpay: false,
            rate: 0,
            onlyPayMethod: '',
            rateInfo: '',
            order_id: '',
        }
    },

    computed: {
        commission() {
            const p = +this.info.price
            return Math.round(
                (p * this.rate + p * this.rate * this.rate) * 100
            ) / 100 ;
        },
        total() {
           const total = +this.info.price + this.commission;
           return total.toFixed(2);
        },
    },

    beforeMount() {
        const query = this.$route.query;
        this.isUnpay = false;
        this.info = {
            payType: query.t,
            price: query.p,
            name: query.n,
            class: query.c,
            organ: query.o,
            mobile: query.pm,
            payName: query.pn,
            merchant_id: query.m,
            idCard: query.id,
            remark: query.r,
            subjectId: query.s,
            subjectName: query.sn,
            order_id: query.ao
        };
        if (this.$isAli && this.isUnpay) {
            this.onlyPayMethod = this.info.payType = '1'
        }
        if (this.$isWX && this.isUnpay) {
            this.onlyPayMethod = this.info.payType = '2'
        }
        this.getRate()
    },

    methods: {
        getRate() {
            this.$loading.show();
            const organ_id = localStorage.getItem('organ');
            let payType = this.info.payType - 1 + '';
            if (this.info.payType > 2) {
                payType = 3 + '';
            }

            getRate({
                organ_id: organ_id,
                type: payType,
            }).then(res => {
                this.$loading.hide();
                if (!res.data) {
                    this.canPay = true;
                    this.$toast.error('请求失败，请重试')
                } else {
                    this.canPay = true;
                    this.rate = res.data.tax || 0;
                    this.rateInfo = res.data.taxdetail;
                }
            }).catch(err => {
                this.canPay = false;
                this.$loading.hide();
                this.$toast.error(err.msg || '请求失败，请重试')
            })
        },
        toFix(v) {
            return (+v).toFixed(2);
        },
        submit() {
            this.$loading.show();
            if (this.isUnpay) {
                this.payConfirm()
                return
            }
            const info = this.info;
            const params = {
                type: info.payType,
                subject_price: info.price,
                name: info.name,
                organ_name: info.class,
                organ_id: info.organ,
                parentmobile: info.mobile,
                payname: info.payName,
                merchant_id: info.merchant_id,
                idcard: info.idCard,
                remark: info.remark,
                subjectId: info.subjectId,
                subjectName: info.subjectName,
                order_id: info.order_id
            }
            transferSave(params).then(data => {
                console.log('transferSave', data);
                this.isUnpay = true;
                this.order_id = data.data.order_id;
                this.payConfirm()
            }).catch(err => {
                this.$loading.hide();
                this.$toast.error(err.msg || '订单提交失败');
            });
        },
        payConfirm() {
            if (!this.info.payType) {
                this.$toast.error('请选择支付方式');
                return;
            }
            const param = {
                orgId: localStorage.getItem('organ'),
                idcard: this.info.idCard,
                type: '1',
            }
            if (this.info.payType === '1') {
                this.aliPay(param)
            } else {
                if (this.info.payType === '3' || this.info.payType === '4') {
                    this.ylPayConfirm();
                } else {
                    this.wxPay(param)
                }

            }
        },
        aliPay(param) {
            aliPay(param).then(res => {
                this.$loading.hide();
                localStorage.setItem('payUser', param.idCard)
                const div = document.createElement('div');
                div.innerHTML = res.data;
                document.body.appendChild(div);
                document.forms[0].submit();
            }).catch(err => {
                this.$loading.hide();
                this.$toast.error(err.msg || '请求失败');
            });
        },
        wxPay(param) {
            const openid = localStorage.getItem('openid')
            wxPay({
                idcard: param.idcard,
                openid
            }).then(res => {
                console.log(res);
                this.$loading.hide();
                this.wxPayConfirm(res.data);
            }).catch(err => {
                this.$loading.hide();
                this.$toast.error(err.msg || '请求失败');
            });
        },
        wxPayConfirm(data) {
            if (!window.WeixinJSBridge) {
                this.$error('请在微信中打开');
                return;
            }
            const _this = this;
            window.WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                    "appId": data.appId,     //公众号名称，由商户传入
                    "timeStamp": data.timeStamp,         //时间戳，自1970年以来的秒数
                    "nonceStr": data.nonceStr, //随机串
                    "package": data.package,
                    "signType": data.signType,         //微信签名方式：
                    "paySign": data.paySign //微信签名
                },
                function(res){
                    if(res.err_msg == "get_brand_wcpay_request:ok" ){
                        localStorage.setItem('payUser', _this.id)
                        _this.$router.push({
                            name: 'paySuccess'
                        })
                    }
                }
            );
        },
        ylPayConfirm() {
            let param = {
                orderNo: this.order_id,
                orgId: localStorage.getItem('organ'),
                openId: localStorage.getItem('openid'),
                payType: this.info.payType
            }
            ylPay(param).then(data => {
                console.log('ylPayConfirm', data);
                window.location.href = data.data;
                //this.$loading.hide();
            }).catch(()=>{
              this.$loading.hide();
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.transfer-confirm-wrapper {
    position: relative;
    padding: 0;
    height: 100%;
    padding: 0;
    padding-bottom: 67px;
    .price-info {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fbfbfb;
        padding: 12px;
        border-top: 1px solid #efefef;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > span {
            font-size: 22px;
            color: red;
            font-weight: bold;
        }
        .button-submit {
            width: 120px;
            margin: 0;
        }
    }
}
.transfer-confirm {
    height: 100%;
    overflow-y: auto;
    .confirm-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        padding: 12px 12px 0;
        .left {
            width: 25%;
            height: 80px;
            background: url('../../assets/images/transfer.png') no-repeat center;
            background-size: 80%;
        }
        .right {
            width: 75%;
            padding: 5px 12px;
            padding-left: 24px;
            p {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0;
                font-size: 13px;
                color: #888;
                line-height: 1.3;
                &:first-child {
                    font-size: 15px;
                    color: #333333;
                    margin-bottom: 6px;
                }
            }
        }
    }
    .line {
        margin: 16px 0;
    }
    .transfer-info {
        padding: 0 12px 12px;
        color: #666666;
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;
            font-size: 14px;
            line-height: 36px;
            &.remark-line {
                justify-content: flex-start;
                span {
                    white-space: pre-wrap;
                    word-break: break-word;
                    line-height: 21px;
                    padding: 8px 0;
                    padding-left: 16px;
                }
            }
        }
    }
}
.transfer-item-title {
    font-size: 15px;
    color: #333333;
    padding-bottom: 12px;
    border-bottom: 1px solid #efefef;
    margin-bottom: 16px;
    margin-top: 16px;
}
.pay-type {
    display: flex;
    align-items: center;
    &.isUnpay {
        justify-content: space-between;
        align-items: top;
    }
    span {
        display: flex;
        align-items: center;
    }
}
.pay-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    &.ali-pay-icon {
        background-image: url('../../assets/images/ali_pay.png');
    }
    &.wx-pay-icon {
        background-image: url('../../assets/images/wx_pay.png');
    }
}
.price-tip {
    font-size: 12px;
    color: #FFA726;
    line-height: 1.2;
}
.note {
    width: 100%;
    border: 1px solid #dedede;
    border-radius: 4px;
}
.pay-method {
    margin-top: 0;
    padding: 0;
    & ::v-deep .v-input__control {
        .v-label {
            font-size: 14px;
            color: #666666 !important;
        }
    }
    .pay-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 4px;
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        &.ali-pay-icon {
            background-image: url('../../assets/images/ali_pay.png');
        }
        &.wx-pay-icon {
            background-image: url('../../assets/images/wx_pay.png');
        }
    }
    .pay-method-item {
        margin-bottom: 16px;
    }
}
</style>
